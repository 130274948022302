var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat__contact flex items-center px-2 pt-4 pb-2" },
    [
      _c("Avatar", {
        staticClass: "mr-1",
        attrs: {
          size: "38px",
          name:
            _vm.chat.sender === "agent" ? _vm.chat.agentName : _vm.chat.name,
        },
      }),
      _c(
        "div",
        {
          staticClass:
            "contact__container w-full flex items-center justify-between overflow-hidden",
        },
        [
          _c("div", { staticClass: "contact__info flex flex-col mt-2 w-5/6" }, [
            _c("h5", { staticClass: "font-semibold medium" }, [
              _vm._v(
                _vm._s(
                  _vm.chat.sender === "agent"
                    ? _vm.chat.agentName
                    : _vm.chat.name
                )
              ),
            ]),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.chat.message) } }),
          ]),
          _c(
            "div",
            {
              staticClass:
                "chat__contact__meta flex self-start flex-col items-end w-1/6",
            },
            [
              _c(
                "span",
                { staticClass: "whitespace-no-wrap font-small mb-12" },
                [_vm._v(_vm._s(_vm.chat_time(_vm.chat.time)))]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }