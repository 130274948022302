var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass: "add-new-data-sidebar items-no-padding",
      attrs: {
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
        spacer: "",
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "mt-6 flex items-center justify-between px-6" },
        [
          _c("h4", [_vm._v(_vm._s(_vm.$t("inputs.message")))]),
          _c("feather-icon", {
            staticClass: "cursor-pointer",
            attrs: { icon: "XIcon" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.isSidebarActiveLocal = false
              },
            },
          }),
        ],
        1
      ),
      _c("vs-divider", { staticClass: "mb-0" }),
      _c(
        _vm.scrollbarTag,
        {
          key: _vm.$vs.rtl,
          tag: "component",
          staticClass: "scroll-area--data-list-add-new",
          attrs: { settings: _vm.settings },
        },
        [
          _c("div", { staticClass: "chat__chats-list mb-8" }, [
            _c(
              "ul",
              { staticClass: "chat__active-chats bordered-items" },
              _vm._l(_vm.data, function (chat, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "cursor-pointer" },
                  [_c("chat-message", { attrs: { chat: chat } })],
                  1
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }